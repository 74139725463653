<template>
  <v-container class="ma-0 pa-0">
    <hero title="Awards"></hero>
    <v-row class="ma-1">
      <v-col
        v-for="n in 19"
        :key="n"
        :class="{
          custom2cols: $vuetify.breakpoint.xs,
          custom5cols: $vuetify.breakpoint.smAndUp
        }"
      >
        <v-img
          :src="`/images/awards/award_${20-n}.png`"
          aspect-ratio="1"
          cover
          class="bg-grey-lighten-2"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Awards',
  metaInfo: {
    title: 'Awards',
    meta: [
      { vmid: 'og:title', property: 'og:title', content: 'Awards'},
    ]
  },
}
</script>
<style>
.custom2cols {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}
.custom5cols {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}
.custom6cols {
  width: 16%;
  max-width: 16%;
  flex-basis: 16%;
}
</style>
<style lang="sass">
a
  text-decoration: none
.about-text
  font-size: 1.1rem
  color: #dcdcdc
</style>

